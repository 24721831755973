<template>
  <div class="text-general row">
    <div class="col-sm-4 mb-3">
      <div class="card">
        <div class="card-body">
          <h4>
            <img
              alt="TeleWords"
              class="rounded-circle mr-3"
              width="64"
              :src="`${publicPath}img/telewords.jpg`"
            />
            TeleWords
          </h4>
        </div>
        <div class="list-group list-group-flush" role="tablist">
          <a
            href="#"
            v-for="user in users"
            v-bind:key="user.user_id"
            v-bind:class="{
              active: current !== undefined && user.user_id === current.user_id,
            }"
            v-on:click="switchUser(user.user_id)"
            class="list-group-item list-group-item-action"
            role="tab"
          >
            {{ user.display_name }}
          </a>
        </div>
      </div>
    </div>
    <div class="col-sm-4 mb-3" v-if="current !== undefined">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">{{ current.display_name }}</h5>
          <p class="lead">Real name: {{ current.real_name }}</p>
          <p class="lead">
            Username:
            <a :href="`https://t.me/${current.username}`">{{
              current.username
            }}</a>
          </p>
          <p class="lead">Upcoming words: {{ current.next_words }}</p>
          <p class="lead">Total words requested: {{ current.stats }}</p>
          <p class="lead">Bank in use: {{ current.bank }}</p>
        </div>
      </div>
    </div>
    <div class="col-sm-4 mb-3" v-if="current !== undefined">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Send announcement</h5>
          <div class="form-group">
            <textarea class="form-control" v-model="message"></textarea>
          </div>
          <a class="btn btn-primary" role="button" v-on:click="sendAnnouncement"
            >Send</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Telewords",
  data() {
    return {
      users: [],
      current: undefined,
      message: "",
      publicPath: process.env.BASE_URL,
    };
  },
  methods: {
    switchUser(user_id) {
      for (let user of this.users) {
        if (user.user_id === user_id) this.current = user;
      }
    },
    sendAnnouncement() {
      let self = this;
      this.apis.telewords
        .sendAnnouncement(this.current.username, this.message)
        .then(() => {
          self.message = "";
        });
    },
  },
  mounted() {
    let self = this;
    this.apis.telewords.getUsers().then((result) => {
      self.users = result;
    });
  },
};
</script>

<style scoped></style>
